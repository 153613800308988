import React from 'react'

export default function() {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 137 207"
    >
      <g transform="translate(.5)" fill="none" fillRule="evenodd">
        <g transform="translate(.5 189)" fill="#FC5391">
          <rect fillOpacity=".6" x="1.943" y="4" width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;34"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
          <rect width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;34"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
        </g>
        <g transform="translate(.5 168)" fill="#E34BCF">
          <rect fillOpacity=".6" x="1.943" y="4" width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;34"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
          <rect width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;34"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
        </g>
        <g transform="translate(.5 147)" fill="#DB5FFA">
          <rect fillOpacity=".6" x="1.943" y="4" width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;34"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
          <rect width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;34"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
        </g>
        <g transform="translate(.5 126)" fill="#9C4BE3">
          <rect fillOpacity=".6" x="1.943" y="4" width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;34"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
          <rect width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;34"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
        </g>
        <g transform="translate(.5 105)" fill="#7E53FC">
          <rect fillOpacity=".6" x="2.914" y="4" width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;96"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
          <rect width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;96"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
        </g>
        <g transform="translate(.5 84)" fill="#9C4BE3">
          <rect fillOpacity=".6" x="2.914" y="4" width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;120"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
          <rect width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;120"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
        </g>
        <g transform="translate(.5 63)" fill="#DB5FFA">
          <rect fillOpacity=".6" x=".971" y="4" width="132" height="14" rx="7" />
          <rect width="132" height="14" rx="7" />
        </g>
        <g transform="translate(.5 42)" fill="#E34BCF">
          <rect fillOpacity=".6" x="2.914" y="4" width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;120"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
          <rect width="132" height="14" rx="7">
            <animate id="animation"
              attributeName="width"
              attributeType="XML"
              values="132;120"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
        </g>
        <g transform="translate(.5 21)" fill="#FC5391">
          <rect fillOpacity=".6" x="2.914" y="4" width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;96"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
          <rect width="132" height="14" rx="7">
            <animate
              attributeName="width"
              attributeType="XML"
              values="132;96"
              begin="0" dur="1.2s"
              fill="freeze"
            />
          </rect>
        </g>

        <rect fill="#E34BCF" x=".5" width="15" height="15" rx="7.5" opacity="0">
          <animate
            attributeName="opacity"
            attributeType="XML"
            values="0;1"
            begin="1.5" dur=".1s"
            fill="freeze"
          />
        </rect>
      </g>
    </svg>
  )
}