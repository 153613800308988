import { Link } from "gatsby"
import React from "react"

import Logo from './utils/logo'
import style from '../modules/header.module.scss';

export default function Header() {

 return(
   <header>
    <Link 
      to={`/`}
      className={style.Logo}
    >
      <Logo/>
    </Link>
   </header>
 )
}

