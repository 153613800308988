/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"

// Style
import "../scss/header.scss"


const Layout = ({ children, ...props }) => {
  return (
    <>
      {/* Navbar */}
      <Header {...props}/>

      {/* Main content / Body */}
      <main>{children}</main>
      {/* Footer */}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
